<template>
  <div style="max-widht:640px;margin:0 auto">
    <div class="header">孙姓宝宝起名</div>
    <div class="paybj successbox">
      <img
        class="payicon"
        src="//cdn.zhiyileiju.cn/paysuccess_icon.png"
        alt=""
      />
      <span class="payitp">支付成功</span>
      <ul class="infolist">
        <li>
          <div>订单号：{{orderinfo.code}}(手机号后4位)</div>
        </li>
        <li>
          <div>姓氏：{{ orderinfo.xname }}</div>
        </li>
        <li>
          <div>性别：{{ orderinfo.sex }}</div>
        </li>
        <li>
          <div>
            出生日期：{{ orderinfo.bDate[0] }}年{{ orderinfo.bDate[1] }}月{{
              orderinfo.bDate[2]
            }}日
          </div>
        </li>
        <li>
          <div>手机号码：{{ orderinfo.bind_iphone }}</div>
        </li>
      </ul>
    </div>
    <div class="desc">
      <div>温馨提示</div>
      <div style="margin-top:10px">
        老师将会在您下单的1-2天内把起名方案通过短信和助理微信发送给您，请注意查收。
      </div>
      <div style="margin-top:15px">请添加助理老师</div>
      <ul>
        <li>· 任意问题可以咨询助理老师；</li>
        <li>· 咨询前请发送订单号6943（您手机号后四位）</li>
      </ul>
      <div style="margin-top:10px;line-height:2em">
        建议您添加助理微信，联系专属助理咨询，点击获取微信：<span class="red"
          >wy678681507</span
        >
        <span class="copy" @click="copy('wy678681507')">复制</span>
      </div>
      <img
        style="width:100%;margin-top:20px"
        src="//cdn.zhiyileiju.cn/WX20210805-181701%402x.png"
        alt=""
      />
    </div>
    <div class="wrapper">
      <textarea readonly id="input"></textarea>
    </div>
  </div>
</template>

<script>
import axios from "../utils/request"
import { Toast } from "vant"
	import report from "../utils/stat";
export default {
  data() {
    return {
      orderinfo: {
        xname: "",
        sex: "",
        bind_iphone: "13888888888",
        bDate: [],
      },
    };
  },
  async created(){
    report("v1_msuccess");
    let data =  await axios.get("/order/" + this.$route.params.id);
    let orderinfo = data.data.data;
      let birth = orderinfo.pub_birth_date? orderinfo.pub_birth_date: orderinfo.pub_prebirth_date;
      let fdate = birth.split(" ");
      orderinfo.bDate = fdate[0].split("-");
      orderinfo.code = orderinfo.bind_iphone.substr(7,4);
    this.orderinfo = orderinfo;

  },
  methods:{
    copy(text) {
      var input = document.getElementById("input");
      input.innerText = text;
      input.select();
      document.execCommand("copy");
      Toast.success("复制成功");

    },
  }
};
</script>

<style lang="less" scoped>
.header {
  background: #525252;
  height: 50px;
  width: 100%;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #f4f4f4;
  text-align: center;
  line-height: 50px;
}
.paybj {
  background: url("//cdn.zhiyileiju.cn/5d5057941af5857bf368445a2b537af6.jpeg")
    no-repeat center;
  background-size: 100% 100%;
}

 #input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -10;
}

.successbox {
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 20px);
  margin: 0 auto;
  margin-top: 10px;
}

.payicon {
  width: 70px;
  height: 70px;
  margin-top: 30px;
}

.payitp {
  font-size: 18px;
  font-weight: 400;
  color: #020202;
}

.infolist {
  padding: 10px 35px;
  padding-bottom: 40px;
  li {
    display: flex;
    font-size: 16px;
    line-height: 1.8em;
  }
}

.desc {
  margin-top: 10px;
  background: #fff;
  padding: 25px;
  ul li {
    line-height: 2em;
  }
  .red {
    color: #ff0000;
  }
  .copy {
    display: inline-block;
    padding: 1px 10px;
    margin-left: 10px;
    background: red;
    color: #fff;
    border-radius: 5px;
  }
}
</style>
